import React, { useState } from 'react';
import HeadTags from '../components/HeadTags';
import ReactModal from 'react-modal';
//! Components
import Layout from '../components/layout';
import CTA from '../components/CTA';

//! CSS
import '../styles/style.css';
import '../styles/new.css';
import '../styles/revamp.css';

//! Images
import PlayIcon from '../img/group-8@3x.png';
import Webinar4 from '../img/bnjk@3x-1.jpg';
import close from '../img/lightbox-close.png';

export default class Webinar extends React.Component {
  state = {
    video: false,
    modalIsOpenIndex: 0
  };
  handleOpen = index => {
    this.setState({ modalIsOpenIndex: index });
  };
  handleClose = e => {
    this.setState({ modalIsOpenIndex: 0 });
    e.stopPropagation();
  };
  render() {
    return (
      <Layout>
        <HeadTags
        title={`ChatBots | Customer Service Conversational AI Chatbots `}
        content={{ type: 'article', url: 'legal' }}
        content={{
          type: 'website',
          description:
            'Automate your customer support and engagement with Conversational AI chatbots, used by leading banks, insurance companies and fintechs globally. No technical skills required.'
        }}/>
        <div className="blog webinar">
          <div className="row no-gutters featured-demo">
            <div
              className={`col-12 thumbnail webinar-thumbnail ${
                this.state.video ? 'fade hide' : ''
              }`}
            >
              <p>Featured webinar</p>
              <h2> Setting up a Q&amp;A workspace on Triniti</h2>
              <img
                alt="Play"
                src={PlayIcon}
                className="play-overlay"
                onClick={() => this.setState({ video: true })}
              />
            </div>
            <div className={`col-12 video ${this.state.video ? '' : 'hide'}`}>
              {this.state.video ? (
                <iframe
                  src="https://www.youtube.com/embed/xPNyI4cwovE?autoplay=1"
                  height="500"
                  width="750"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="true"
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="container blog-body webinar-body">
            <div className="row no-gutters">
              <h2 className="col-12">
                Webinars &amp; videos: to help you build conversational
                experiences
              </h2>
            </div>
            <div className="row posts-container">
              <div className="col-lg-4 col-md-5 col-sm-12 post">
                <div
                  className="webinar-border"
                  onClick={() => {
                    this.handleOpen(1);
                  }}
                >
                  {this.state.modalIsOpenIndex === 1 ? (
                    <ReactModal
                      isOpen={true}
                      style={{
                        overlay: {
                          opacity: '0.9',
                          'background-color': 'rgb(0, 0, 0)',
                          'z-index': '299'
                        },
                        content: {
                          width: '580px',
                          height: '380px',
                          top: '25%',
                          left: '30%',
                          overflow: 'hidden',
                          padding: '9px 0px 0px 9px'
                        }
                      }}
                    >
                      <div
                        id="html5-close"
                        className="close"
                        onClick={event => {
                          this.handleClose(event);
                        }}
                      >
                        <img alt="Close" src={close} width="32" height="32" />
                      </div>
                      <iframe
                        width="560px"
                        height="360px"
                        src="https://www.youtube.com/embed/xPNyI4cwovE"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </ReactModal>
                  ) : (
                    ''
                  )}
                </div>

                <div className="col-12 image-container">
                  <img
                    width="1134"
                    height="720"
                    src={Webinar4}
                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                    alt="Webinar"
                  />
                  <img alt="Play" src={PlayIcon} className="play-overlay" />
                </div>
                <h3 className="title col-12"> Webinar 1</h3>
                <p className="excerpt col-12">
                  Active.Ai's Triniti.Ai Global Launch with AWS - AI in Banking
                  & FinTech on Cloud
                </p>
              </div>
              {/* <div className="col-lg-4 col-md-5 col-sm-12 post">
          <div className="webinar-border" onClick={()=>{this.handleOpen(3)}}>
            {this.state.modalIsOpenIndex===3 ?
              <ReactModal
                isOpen={true}
                style={{overlay:{opacity:'0.9', 'background-color': 'rgb(0, 0, 0)', 'z-index':'299'},
                content:{width:'580px', height:'380px',top:'25%',left:'30%',overflow: 'hidden',padding:'9px 0px 0px 9px'}}} 
                >
                  <div id="html5-close" className="close" onClick={(event)=>{this.handleClose(event)}}>
                    <img alt="" 
                    src={close}
                    width="32"
                    height="32"
                    />
                    </div>
                  <iframe width="560px" height="360px"  src="https://www.youtube.com/embed/xPNyI4cwovE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </ReactModal>
              :''}
            </div>
            <div className="col-12 image-container">
              <img
                width="1134"
                height="720"
                src={Webinar3}
                className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                alt=""
              />
              <img src={PlayIcon} className="play-overlay" />
            </div>
            <h3 className="title col-12"> Webinar 3</h3>
            <p className="excerpt col-12">
              Sed tincidunt condimentum turpis, ac gravida tellus ultrices
              pellentesque. Vivamus…
            </p>
          </div>
          <div className="col-lg-4 col-md-5 col-sm-12 post"> */}
              {/* <div className="webinar-border" onClick={()=>{this.handleOpen(2)}}>
            {this.state.modalIsOpenIndex===2 ?
              <ReactModal
                isOpen={true}
                style={{overlay:{opacity:'0.9', 'background-color': 'rgb(0, 0, 0)', 'z-index':'299'},
                content:{width:'580px', height:'380px',top:'25%',left:'30%',overflow: 'hidden',padding:'9px 0px 0px 9px'}}} 
                >
                  <div id="html5-close" className="close" onClick={(event)=>{this.handleClose(event)}}>
                    <img alt="" 
                    src={close}
                    width="32"
                    height="32"
                    />
                    </div>
                  <iframe width="560px" height="360px"  src="https://www.youtube.com/embed/xPNyI4cwovE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </ReactModal>
              :''}
            </div>
            <div className="col-12 image-container">
              <img
                width="1134"
                height="720"
                src={Webinar2}
                className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                alt=""
              />
              <img src={PlayIcon} className="play-overlay" />
            </div>
            <h3 className="title col-12"> Webinar 2</h3>
            <p className="excerpt col-12">
              Vivamus ex eros, ultricies ac metus nec, facilisis tempus elit.…
            </p>
          </div> */}
              {/* <div className="col-lg-4 col-md-5 col-sm-12 post"> */}
              {/* <div className="webinar-border" onClick={()=>{this.handleOpen(1)}}> */}
              {/* {this.state.modalIsOpenIndex===1 ?
              <ReactModal
                isOpen={true}
                style={{overlay:{opacity:'0.9', 'background-color': 'rgb(0, 0, 0)', 'z-index':'299'},
                content:{width:'580px', height:'380px',top:'25%',left:'30%',overflow: 'hidden',padding:'9px 0px 0px 9px'}}} 
                >
                  <div id="html5-close" className="close" onClick={(event)=>{this.handleClose(event)}}>
                    <img alt="" 
                    src={close}
                    width="32"
                    height="32"
                    />
                    </div>
                  <iframe width="560px" height="360px"  src="https://www.youtube.com/embed/xPNyI4cwovE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </ReactModal>
              :''}
            </div> */}
              {/* <div className="col-12 image-container">
              <img
                width="1134"
                height="720"
                src={Webinar1}
                className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                alt=""
              />
              <img src={PlayIcon} className="play-overlay" />
            </div>
            <h3 className="title col-12"> Webinar 1</h3>
            <p className="excerpt col-12">
              Sample Excerpt 3 Sample Excerpt 6 Sample Excerpt 9 Sample…
            </p>
          </div> */}
            </div>
          </div>
        </div>
        <CTA />
      </Layout>
    );
  }
}
